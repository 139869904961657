<template>
  <div class="lock">
    <div class="lock__container">
      <div class="lock__top-container" v-if="topSection">
        <div class="lock__top-container-number">
          {{ unitSerialNumber }}
        </div>
        <div class="lock__top-container-label">
          {{ barrier?.variety?.title }}
        </div>
      </div>
      <div class="lock__image">
        <div v-if="barrier?.attachment">
          <img
            v-if="!barrier.attachment.video"
            :src="barrier.attachment.url"
            alt=""
          >
          <img
            v-if="barrier.attachment.video"
            src="../../../assets/image/video.png"
            alt=""
          >
        </div>
        <img
          v-else
          src="../../../assets/image/barrier.png"
          alt=""
        >
      </div>
      <div class="lock__type">
        {{ barrier?.type?.title }}
      </div>
      <div class="lock__name">
        {{ barrier?.device?.name }}
      </div>
      <div class="lock__lock_name">
        {{ barrier?.title }}
      </div>
      <div class="lock__tools">
        <Edit
          v-if="barrierEditButton"
          @click="editBarrier(barrier.id)"
        />
        <Delete
          v-if="barrierDeleteButton"
          @click="deleteBarrier(barrier.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Delete from '@/assets/svg/delete.svg?inline'
import Edit from '@/assets/svg/edit.svg?inline'
export default {
  name: 'BarriersCard',
  components: {
    Delete,
    Edit
  },
  props: {
    barrier: {
      type: Object,
      default: () => {
        return {}
      }
    },
    unitSerialNumber: {
      type: [String, Number],
      default: ''
    },
    topSection: {
      type: Boolean,
      default: false
    },
    barrierEditButton: {
      type: Boolean,
      default: false
    },
    editBarrier: {
      type: Function,
      default: () => {
        return false
      }
    },
    deleteBarrier: {
      type: Function,
      default: () => {
        return false
      }
    },
    barrierDeleteButton: String
  }

}
</script>

<style lang="scss" scoped>
.lock {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 15px;

  &__container {
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 280px;
    position: relative;
  }

  &__image {
    display: flex;
    align-items: center;
    height: 150px;
    img {
      width: 140px;
      height: 144px;
      object-fit: contain;
    }
  }

  &__lock_name {
    font-size: 14px;
    overflow: hidden;
    height: 35px;
  }

  &__top-container {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__top-container-number {
    background: #8cb4b1;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 800;
    font-size: 12px;
    border-radius: 100px;
  }

  &__top-container-label {
    background: #414141;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    width: 80px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  &__name {
    font-size: 14px;
    color: #8c8c8c;
    margin: 10px 0 5px 0;
    height: 20px;
    overflow: hidden;
  }

  &__type {
    font-weight: 500;
    font-size: 12px;
    width: 100px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #546c48;
    border-radius: 50px;
    margin-top: 15px;
    color: #ffffff;
  }

  &__tools {
    svg {
      width: 36px;
      height: 38px;
      cursor: pointer;
      margin: 10px 5px 0 5px;
    }
  }

  &__button-open {
    @include second-button;
    font-size: 14px;
    height: 40px;
    width: 150px;
  }
}
</style>
